import DialogContent from '@mui/material/DialogContent';
import React from 'react';

interface Props {
  children: any;
  align?: 'default' | 'left';
  justifyContent?: 'default' | 'start';
}

const Content: React.FC<Props> = ({ children, align = 'default', justifyContent = 'default' }) => {
  return (
    <DialogContent
      sx={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: align === 'left' ? 'flex-start' : 'center',
        justifyContent: justifyContent === 'start' ? 'flex-start' : 'center',
        gap: align === 'left' ? '8px' : '16px',
        textAlign: align === 'left' ? 'left' : 'center',
      }}
    >
      {children}
    </DialogContent>
  );
};

export default Content;
