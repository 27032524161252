import styled from '@emotion/styled';
import React from 'react';

interface Props {
  children: any;
}

const StyledSpan = styled.span`
  font-size: var(--text-subhead-size);
  color: var(--common-white, #fff);
`;

const Paragraph: React.FC<Props> = ({ children }) => {
  return <StyledSpan>{children}</StyledSpan>;
};

export default Paragraph;
