import React from 'react';
import { MuiThemeProviderProps } from './MuiThemeProvider.types';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#40cd1c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#20c075',
      contrastText: '#fff',
    },
    error: {
      main: '#f15132',
      contrastText: '#fff',
    },
    info: {
      main: '#2f415b',
      contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    allVariants: {
      fontFamily: 'Roboto',
    },
    button: {
      fontWeight: 700,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: '48px',
          fontSize: `var(--text-body-size, 16px)`,
          textTransform: 'initial',
        },
      },
      defaultProps: {
        variant: 'contained',
        color: 'primary',
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '14px 16px',
        },
        notchedOutline: {
          borderColor: 'transparent',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: 'var(--iq-robot-background, #1b1f28)',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '999999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
  },
});

const MuiThemeProvider: React.FC<MuiThemeProviderProps> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default React.memo(MuiThemeProvider);
