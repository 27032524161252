import React from 'react';
import {
  EmailExistErrorModal,
  NetworkErrorModal,
  SomethingWentWrongErrorModal,
  VpnErrorModal,
  LoginFailedErrorModal,
  AccountNotFoundModal,
  AccountBlockedModal,
  TwoAuthLoginErrorModal,
} from '../ErrorModals';
import { ModalType } from '@/context/ModalContext';

interface Props {
  name: ModalType;
}

const ModalMapper: React.FC<Props> = ({ name }) => {
  if (!name) {
    return null;
  }

  if (name === ModalType.EMAIL_EXIST) {
    return <EmailExistErrorModal />;
  }

  if (name === ModalType.NETWORK_ERROR) {
    return <NetworkErrorModal />;
  }

  if (name === ModalType.REQUEST_NETWORK_ERROR) {
    return <NetworkErrorModal />;
  }

  if (name === ModalType.SOMETHING_WENT_WRONG) {
    return <SomethingWentWrongErrorModal />;
  }

  if (name === ModalType.NEED_VPN) {
    return <VpnErrorModal />;
  }

  if (name === ModalType.INVALID_CREDENTIALS) {
    return <LoginFailedErrorModal />;
  }

  if (name === ModalType.ACCOUNT_AUTH_NOT_FOUND) {
    return <AccountNotFoundModal />;
  }

  if (name === ModalType.ACCOUNT_AUTH_BLOCKED) {
    return <AccountBlockedModal />;
  }

  if (name === ModalType.TWO_AUTH_LOGIN) {
    return <TwoAuthLoginErrorModal />;
  }

  return <SomethingWentWrongErrorModal />;
};

export default React.memo(ModalMapper);
