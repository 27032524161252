import React, { useCallback } from 'react';
import { useModalContext } from '@/context/ModalContext';
import { SomethingWentWrongErrorIcon } from '@/icons/errors';
import BaseModal from '@/components/Modal/BaseModal/BaseModal';
import {
  ModalActions,
  ModalContent,
  ModalContentText,
  ModalHeader,
  ModalIcon,
  ModalSubmitButton,
  ModalTitle,
} from '@/components/Modal/Elements';
import CloseButton from '@/components/Modal/Elements/Close/CloseButton';
import { useTranslation } from '@/hooks/useTranslation';

interface Props {}

const SomethingWentWrongErrorModal: React.FC<Props> = ({}) => {
  const { t } = useTranslation();
  const { closeAll } = useModalContext();
  const onClose = useCallback(() => {
    closeAll();
  }, []);
  const onSubmit = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <BaseModal onClose={onClose}>
      <ModalHeader>
        <CloseButton onClick={onClose} />
      </ModalHeader>
      <ModalIcon>
        <SomethingWentWrongErrorIcon />
      </ModalIcon>
      <ModalContent>
        <ModalTitle>{t('modal.error.something_went_wrong.title')}</ModalTitle>
        <ModalContentText>{t('modal.error.something_went_wrong.sub_title')}</ModalContentText>
      </ModalContent>
      <ModalActions>
        <ModalSubmitButton onClick={onSubmit} text={t('button.try_again')} />
      </ModalActions>
    </BaseModal>
  );
};

export default SomethingWentWrongErrorModal;
