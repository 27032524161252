import DialogContentText from '@mui/material/DialogContentText';
import React from 'react';

interface Props {
  children: any;
}

const ContentText: React.FC<Props> = ({ children }) => {
  return (
    <DialogContentText
      sx={{
        textAlign: 'inherit',
        fontWeight: 400,
        color: 'var(--common-text-grey, #98a4b3)',
        padding: 0,
      }}
    >
      {children}
    </DialogContentText>
  );
};

export default React.memo(ContentText);
