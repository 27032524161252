import React from 'react';

const SuccessIcon = () => {
  return (
    <svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="30.5" stroke="#40CD1C" strokeWidth="3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.454 23.423c.682.603.732 1.63.112 2.295L29.79 40.468c-.307.329-.739.521-1.195.531a1.693 1.693 0 0 1-1.22-.475L20.49 33.82a1.594 1.594 0 0 1 0-2.298 1.702 1.702 0 0 1 2.36 0l5.65 5.5 12.597-13.49a1.701 1.701 0 0 1 2.358-.108Z"
        fill="#40CD1C"
      />
    </svg>
  );
};

export default SuccessIcon;
