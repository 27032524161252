import React, { useCallback } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { AuthContainer } from './Container';
import { useAuthContext } from '@/context/AuthContext';
import { useMobile } from '@/hooks/useMobile';
import { AnalyticClient } from '@repo/utils';

export interface Props {}

const DRAWER_DESKTOP_SX = {};

const DRAWER_MOBILE_SX = {
  height: '100%',
};

const Auth: React.FC<Props> = () => {
  const { opened } = useAuthContext();
  const isMobile = useMobile();

  const handleCrossClick = useCallback(() => {
    opened.onFalse();
    AnalyticClient.track({
      type: 'overlay_close-btn-click',
      data: {
        parent_element_1: 'subscription_screen',
        parent_element_2: 'registration_overlay',
      },
    });
  }, [opened.onFalse]);

  return (
    <Drawer
      PaperProps={{
        sx: isMobile ? DRAWER_MOBILE_SX : DRAWER_DESKTOP_SX,
      }}
      anchor={isMobile ? 'bottom' : 'right'}
      open={opened.value}
      onClose={opened.onFalse}
    >
      {isMobile && (
        <Box display="flex" justifyContent="flex-end" padding="4px" marginBottom="14px">
          <IconButton sx={{ padding: '8px' }} onClick={handleCrossClick}>
            <Close />
          </IconButton>
        </Box>
      )}
      <AuthContainer />
    </Drawer>
  );
};

export default React.memo(Auth);
