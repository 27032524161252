import React from 'react';
import MuiIcon from '@mui/material/Icon';

interface Props {
  children: any;
  size?: 'default' | 'l';
}

const Icon: React.FC<Props> = ({ children, size = 'default' }) => {
  return (
    <MuiIcon
      sx={{
        width: '100%',
        height: size === 'l' ? 'auto' : 64,
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {children}
    </MuiIcon>
  );
};

export default React.memo(Icon);
