import { useCallback } from 'react';
import { useTranslation as _useTranslation } from 'react-i18next';

export type ReturnT = ReturnType<typeof useTranslation>;

export const useTranslation = (ns?: string) => {
  const { t: tr, i18n } = _useTranslation(ns ?? 'common');

  const t = useCallback((ts: string, options = {}) => tr(ts, options), [tr]);

  return {
    t,
    i18n,
  };
};
