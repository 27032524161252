import DialogActions from '@mui/material/DialogActions';
import React from 'react';

interface Props {
  children: any;
}

const Actions: React.FC<Props> = ({ children }) => {
  return (
    <DialogActions
      disableSpacing
      sx={{
        flexDirection: 'column',
        gap: '12px',
        padding: 0,
      }}
    >
      {children}
    </DialogActions>
  );
};

export default Actions;
