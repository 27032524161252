import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  children: any;
  size?: 'default' | 'medium';
}

const Title: React.FC<Props> = ({ children, size = 'default' }) => {
  return (
    <DialogTitle
      sx={{
        fontWeight: 700,
        textAlign: 'center',
        padding: 0,
        fontSize: size === 'medium' ? 'var(--text-h4-size, 18px)' : 'var(--text-h3-size, 22px)',
      }}
    >
      {children}
    </DialogTitle>
  );
};

export default React.memo(Title);
