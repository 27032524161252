import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import { useMobile } from '@/hooks/useMobile';

interface Props {
  children: any;
}

const Heading: React.FC<Props> = ({ children }) => {
  const isMobile = useMobile();
  return (
    <DialogTitle
      sx={{
        fontWeight: 700,
        textAlign: 'center',
        padding: 0,
        fontSize: isMobile ? 'var(--text-h4-size, 18px)' : 'var(--text-h3-size, 22px)',
      }}
    >
      {children}
    </DialogTitle>
  );
};

export default React.memo(Heading);
