import React from 'react';

const NetworkErrorIcon = () => {
  return (
    <svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="30.5" stroke="#F15132" strokeWidth="3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.689 33.097a13.536 13.536 0 0 1 3.9 2.72c.768.74 2.026.73 2.78-.025a2.003 2.003 0 0 0 .024-2.78c-3.098-3.097-7.133-4.798-11.26-5.104l4.556 5.19Zm-8.054-9.172c6.271-.614 12.758 1.48 17.562 6.284.767.741 2.025.73 2.78-.024a2.002 2.002 0 0 0 .023-2.78c-6.373-6.374-15.254-8.712-23.468-7.014l3.103 3.534Zm-9.396-1.352 2.723 3.101a21.759 21.759 0 0 0-6.61 4.535 2.002 2.002 0 0 1-2.779-.024 2.002 2.002 0 0 1-.024-2.78 25.745 25.745 0 0 1 6.69-4.832Zm5.53 6.298 2.881 3.282a13.666 13.666 0 0 0-6.69 3.663 2.002 2.002 0 0 1-2.78-.024 2.002 2.002 0 0 1-.024-2.78 17.466 17.466 0 0 1 6.613-4.14Zm6.046 6.887c-2.565-.011-5.134.944-7.053 2.864a2.004 2.004 0 0 0 0 2.806 2.003 2.003 0 0 0 2.805 0c2.29-2.29 6.12-2.29 8.411 0 .36.36.836.55 1.318.571l-5.48-6.242Zm-.04 7.89c-1.08 0-1.983.903-1.983 1.983s.903 1.983 1.983 1.983h.02c1.08 0 1.982-.903 1.982-1.983s-.903-1.983-1.983-1.983h-.02ZM19.205 16.371a1.542 1.542 0 0 1 2.174.168l23.132 26.987a1.542 1.542 0 0 1-2.341 2.008L19.037 18.546a1.542 1.542 0 0 1 .168-2.175Z"
        fill="#fff"
      />
    </svg>
  );
};

export default NetworkErrorIcon;
