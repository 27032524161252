import { styled } from '@mui/material/styles';
import MuiTab from '@mui/material/Tab';
import React from 'react';

export interface TabProps {
  value: string;
  label: string;
}

interface Props extends TabProps { }

const StyledTab = styled((props: Props) => <MuiTab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: 16,
  lineHeight: 1.5,
  gap: theme.spacing(1),
  color: `var(--common-text-grey, #98a4b3)`,
  flex: 1,
  '&.Mui-selected': {
    color: 'var(--common-white, #fff)',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

export default React.memo(StyledTab);
