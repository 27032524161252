import React, { useCallback } from 'react';
import { ModalType, useModalContext } from '@/context/ModalContext';
import { SomethingWentWrongErrorIcon } from '@/icons/errors';
import BaseModal from '@/components/Modal/BaseModal/BaseModal';
import {
  ModalActions,
  ModalContent,
  ModalContentText,
  ModalHeader,
  ModalIcon,
  ModalCancelButton,
  ModalTitle,
} from '@/components/Modal/Elements';
import CloseButton from '@/components/Modal/Elements/Close/CloseButton';
import { useTranslation } from '@/hooks/useTranslation';

interface Props { }

const AccountBlockedModal: React.FC<Props> = ({ }) => {
  const { t } = useTranslation();
  const { close } = useModalContext();
  const onClose = useCallback(() => {
    close(ModalType.ACCOUNT_AUTH_BLOCKED);
  }, []);

  return (
    <BaseModal onClose={onClose}>
      <ModalHeader>
        <CloseButton onClick={onClose} />
      </ModalHeader>
      <ModalIcon>
        <SomethingWentWrongErrorIcon />
      </ModalIcon>
      <ModalContent>
        <ModalTitle>{t('modal.error.account_blocked.title')}</ModalTitle>
        <ModalContentText>{t('modal.error.account_blocked.sub_title')}</ModalContentText>
      </ModalContent>
      <ModalActions>
        <ModalCancelButton onClick={onClose} text={t('button.ok')} />
      </ModalActions>
    </BaseModal>
  );
};

export default AccountBlockedModal;
