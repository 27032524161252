import React, { useCallback, useState } from 'react';
import { AuthTabs } from '../Tabs';
import { AuthLoginForm } from '../Login';
import styled from '@emotion/styled';
import { AuthRegisterForm } from '../Register';
import { UserScheme } from '@repo/utils';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useBoolean } from '@/hooks/useBoolean';
import { ResetPasswordForm } from '@/components/ResetPasswordForm';
import { AuthTabsValues, useAuthContext } from '@/context/AuthContext';
import { useMobile } from '@/hooks/useMobile';

interface Props {}

const TABS = [
  {
    value: AuthTabsValues.Register,
    label: 'text.register',
  },
  {
    value: AuthTabsValues.Login,
    label: 'text.login',
  },
];

const schema = z.object({
  email: UserScheme.email(),
  password: UserScheme.password().min(6),
});

const FormRoot = styled.div<{ isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? '100%' : '320px')};
  max-width: 100%;
  padding: 12px 16px;
  gap: 16px;
  box-sizing: border-box;
`;

const AuthContainer: React.FC<Props> = () => {
  const { tabState } = useAuthContext();
  const [tab, setTab] = tabState;
  const showRestorePassword = useBoolean();
  const isMobile = useMobile();
  const FormState = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(schema),
  });
  const handleChangeTab = useCallback((t: string) => {
    FormState.clearErrors();
    setTab(t as AuthTabsValues);
  }, []);
  const handleRestorePassword = useCallback(() => {
    showRestorePassword.onTrue();
  }, []);

  return (
    <FormRoot isMobile={isMobile}>
      {showRestorePassword.value ? (
        <ResetPasswordForm onBack={showRestorePassword.onFalse} />
      ) : (
        <>
          <AuthTabs value={tab} onTabChange={handleChangeTab} tabs={TABS} sx={{ marginBottom: '16px' }} />
          {tab === TABS[0].value && <AuthRegisterForm FormState={FormState} />}
          {tab === TABS[1].value && <AuthLoginForm FormState={FormState} onRestorePassword={handleRestorePassword} />}
        </>
      )}
    </FormRoot>
  );
};

export default React.memo(AuthContainer);
