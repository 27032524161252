import React from 'react';
import styled from '@emotion/styled';

interface Props {
  children: any;
}

const StyledSpan = styled.span`
  font-weight: 700;
  font-size: var(--text-h4-size, 18px);
`;

const SubHeader: React.FC<Props> = ({ children }) => {
  return <StyledSpan>{children}</StyledSpan>;
};

export default React.memo(SubHeader);
