import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

interface Props {
  onClick: () => void;
  color?: ButtonProps['color'];
  text: string;
}

const SubmitButton: React.FC<Props> = ({ onClick, color = 'primary', text }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        textTransform: 'initial',
      }}
      fullWidth
      color={color}
    >
      {text}
    </Button>
  );
};

export default React.memo(SubmitButton);
