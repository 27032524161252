import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

interface Props {
  color?: string;
}
const blink = keyframes`
  50% {
    fill: transparent;
  }
`;

const Dot = styled.circle`
  animation: 1s ${blink} infinite;
  &:nth-child(2) {
    animation-delay: 250ms;
  }
  &:nth-child(3) {
    animation-delay: 500ms;
  }
`;

const Loader: React.FC<Props> = (props) => {
  const { color = 'white' } = props;
  const defaultProps = {
    fill: color,
  };
  return (
    <svg width="36" height="8" viewBox="0 0 36 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Dot cx="4" cy="4" r="4" {...defaultProps} />
      <Dot cx="19" cy="4" r="3" {...defaultProps} />
      <Dot cx="33" cy="4" r="3" {...defaultProps} />
    </svg>
  );
};

export default React.memo(Loader);
