import React from 'react';
import { AuthTabsValues, useAuthContext } from '@/context/AuthContext';
import { AuthForm } from '@/components/Auth/Form';
import { UseFormReturn } from 'react-hook-form';

interface Props {
  FormState: UseFormReturn<{ email: string; password: string }>;
}

const AuthRegisterForm: React.FC<Props> = ({ FormState }) => {
  const { RegisterRequest } = useAuthContext();

  return <AuthForm type={AuthTabsValues.Register} Request={RegisterRequest} FormState={FormState} />;
};

export default AuthRegisterForm;
