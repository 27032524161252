import { useModalStack } from '@/hooks/useModalStack';
import { RequestErrors } from '@repo/utils';
import React, { createContext, useCallback, useContext, useMemo } from 'react';

interface ModalState {
  stack: ModalType[];
}

interface Context {
  modalState: ModalState;
  closeAll: () => void;
  open: (modal: ModalType) => void;
  close: (modal: ModalType) => void;
}

const ModalContext = createContext({} as Context);

export const useModalContext = () => useContext(ModalContext);

export enum ModalType {
  EMAIL_EXIST = RequestErrors.EMAIL_EXIST,
  NETWORK_ERROR = RequestErrors.NETWORK_ERROR,
  REQUEST_NETWORK_ERROR = RequestErrors.REQUEST_NETWORK_ERROR,
  NEED_VPN = RequestErrors.NEED_VPN,
  INVALID_CREDENTIALS = RequestErrors.INVALID_CREDENTIALS,
  ACCOUNT_AUTH_NOT_FOUND = RequestErrors.ACCOUNT_AUTH_NOT_FOUND,
  ACCOUNT_AUTH_BLOCKED = RequestErrors.ACCOUNT_AUTH_BLOCKED,
  TWO_AUTH_LOGIN = RequestErrors.TWO_AUTH_LOGIN,
  SOMETHING_WENT_WRONG = RequestErrors.UNKNOWN_ERROR,
}

const ModalContextProvider = ModalContext.Provider;

interface Props {
  children?: any;
}

export const ModalProvider = (props: Props) => {
  const { children } = props;

  const { stack, open, close, closeAll } = useModalStack<ModalType>();
  const modalState = useMemo(() => ({ stack }), [stack]);

  const analyticsOpenWrapper = useCallback(
    (modal: ModalType) => {
      open(modal);
    },
    [screen],
  );

  const contextValue = useMemo(
    () => ({
      modalState,
      closeAll,
      open: analyticsOpenWrapper,
      close,
    }),
    [modalState, closeAll, open, close, analyticsOpenWrapper],
  );

  return <ModalContextProvider value={contextValue}>{children}</ModalContextProvider>;
};
