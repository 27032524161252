import MuiTabs, { TabsProps } from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import React, { useCallback } from 'react';
import Tab, { TabProps } from './Tab';
import { useTranslation } from '@/hooks/useTranslation';

interface Props {
  value: string;
  onTabChange: (tab: string) => void;
  tabs: TabProps[];
}

const StyledTabs = styled(MuiTabs)`
  &.MuiTabs-root {
    width: 100%;
  }
  & .MuiTabs-indicator {
    margin-bottom: 6px;
    width: 100%;
  }
  & .MuiTabs-flexContainer {
    gap: 16px;
  }
`;

const Tabs: React.FC<Props & TabsProps> = (props) => {
  const { t } = useTranslation();
  const { value, onTabChange, tabs, ...rest } = props;

  const handleChange = useCallback((_: React.SyntheticEvent<Element, Event>, value: string) => {
    onTabChange(value);
  }, []);

  return (
    <StyledTabs value={value} onChange={handleChange} centered {...rest}>
      {tabs.map((tab) => (
        <Tab key={tab.value} value={tab.value} label={t(tab.label)} />
      ))}
    </StyledTabs>
  );
};

export default React.memo(Tabs);
