import { useEffect, useRef } from "react";

export const useValueRef = <T>(v: T) => {
  const ref = useRef<T>(v);
  useEffect(() => {
    ref.current = v;
  }, [v]);

  return ref;
};
