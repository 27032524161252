import React from 'react';

const SomethingWentWrongErrorIcon = () => {
  return (
    <svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 61c16.016 0 29-12.984 29-29S48.016 3 32 3 3 15.984 3 32s12.984 29 29 29Zm0 3c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32Z"
        fill="#F15132"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.462 22.462a1.579 1.579 0 0 1 2.233 0L32 29.767l7.305-7.305a1.579 1.579 0 1 1 2.233 2.233L34.233 32l7.305 7.305a1.579 1.579 0 1 1-2.233 2.233L32 34.233l-7.305 7.305a1.579 1.579 0 1 1-2.233-2.233L29.767 32l-7.305-7.305a1.579 1.579 0 0 1 0-2.233Z"
        fill="#fff"
      />
    </svg>
  );
};

export default SomethingWentWrongErrorIcon;
