import { useCallback, useMemo } from 'react';
import { IqUserDto } from '@/Iq/types';
import { NisyClient, NisyEvents, NisyTrackOptions } from '@repo/utils';

export const useNisy = () => {
  const onTrack = useCallback(async (event: NisyEvents, data: Record<string, any>, options?: NisyTrackOptions) => {
    await NisyClient.track(event, data, options);
  }, []);

  const onIdentify = useCallback(async (user: IqUserDto) => {
    await NisyClient.identify(user);
  }, []);

  return useMemo(
    () => ({
      onTrack,
      onIdentify,
    }),
    [onTrack, onIdentify],
  );
};
