import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import commonTranslations from './locales/common.json';
import enPhrase from './locales/en/phrase.json';
import arPhrase from './locales/ar/phrase.json';
import esPhrase from './locales/es/phrase.json';
import frPhrase from './locales/fr/phrase.json';
import hiPhrase from './locales/hi/phrase.json';
import idPhrase from './locales/id/phrase.json';
import kkPhrase from './locales/kk-KZ/phrase.json';
import koPhrase from './locales/ko/phrase.json';
import msPhrase from './locales/ms/phrase.json';
import ptPhrase from './locales/pt-BR/phrase.json';
import ruPhrase from './locales/ru/phrase.json';
import thPhrase from './locales/th/phrase.json';
import tlPhrase from './locales/tl/phrase.json';
import trPhrase from './locales/tr/phrase.json';
import ukPhrase from './locales/uk/phrase.json';
import zhPhrase from './locales/zh/phrase.json';

const DEFAULT_NS = 'common';
const DEFAULT_LANG = 'en';

const LOCALES = [
  DEFAULT_LANG,
  'ar',
  'es',
  'fr',
  'hi',
  'id',
  'kk-KZ',
  'ko',
  'ms',
  'pt-BR',
  'ru',
  'th',
  'tl',
  'tr',
  'uk',
  'zh',
];

i18n.use(initReactI18next).init({
  supportedLngs: LOCALES,
  fallbackNS: DEFAULT_NS,
  defaultNS: DEFAULT_NS,
  ns: [DEFAULT_NS, 'phrase'],
  lng: DEFAULT_LANG,
  fallbackLng: DEFAULT_LANG,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      common: commonTranslations,
      phrase: enPhrase,
    },
    ar: {
      common: commonTranslations,
      phrase: arPhrase,
    },
    es: {
      common: commonTranslations,
      phrase: esPhrase,
    },
    fr: {
      common: commonTranslations,
      phrase: frPhrase,
    },
    hi: {
      common: commonTranslations,
      phrase: hiPhrase,
    },
    id: {
      common: commonTranslations,
      phrase: idPhrase,
    },
    'kk-KZ': {
      common: commonTranslations,
      phrase: kkPhrase,
    },
    ko: {
      common: commonTranslations,
      phrase: koPhrase,
    },
    ms: {
      common: commonTranslations,
      phrase: msPhrase,
    },
    'pt-BR': {
      common: commonTranslations,
      phrase: ptPhrase,
    },
    ru: {
      common: commonTranslations,
      phrase: ruPhrase,
    },
    th: {
      common: commonTranslations,
      phrase: thPhrase,
    },
    tl: {
      common: commonTranslations,
      phrase: tlPhrase,
    },
    tr: {
      common: commonTranslations,
      phrase: trPhrase,
    },
    uk: {
      common: commonTranslations,
      phrase: ukPhrase,
    },
    zh: {
      common: commonTranslations,
      phrase: zhPhrase,
    },
  },
});
