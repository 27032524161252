import { useCallback, useRef, useState } from 'react';

import { CancelTokenSource } from 'axios';
import {
  RequestStatuses,
  RequestMethods,
  RequestErrors,
  HttpError,
  isFunction,
  generateHttpRequestCancelSource,
  sendHttpRequest,
} from '@repo/utils';

export type RequestFunc = (data?: any, options?: any) => Promise<any>;

export interface Request {
  data?: Record<string, any>;
  urlParams?: Record<string, string>;
  ignoreErrors?: boolean;
}

export interface State<T = any> {
  errors?: HttpError[];
  isProcessing?: boolean;
  request: Request;
  result: Record<string, T>;
  status: RequestStatuses;
}

type UrlFunc = (urlParams?: Record<string, string | number>) => string;

interface IUseRequestProps {
  domain?: string;
  url: string | UrlFunc;
  urlParams?: Record<string, string>;
  method: RequestMethods;
  withAbort?: boolean;
  formatData?: (data: any) => any;
  withCredentials?: boolean;
  trackError?: boolean;
}

export const DEFAULT_STATE: State = {
  status: RequestStatuses.Initial,
  isProcessing: false,
  result: {},
  errors: [],
  request: {},
};

export function getUrl({ domain, url, urlParams }: Pick<IUseRequestProps, 'domain' | 'url' | 'urlParams'>) {
  if (isFunction(url)) {
    // @ts-ignore
    return url(urlParams);
  }
  return url;
}

export type UseRequest = ReturnType<typeof useRequest>;

export const useRequest = ({
  domain,
  url,
  urlParams,
  method,
  withAbort,
  formatData,
  withCredentials = true,
  trackError = true,
}: IUseRequestProps) => {
  const [state, setState] = useState<State>(DEFAULT_STATE);
  const abortController = useRef<CancelTokenSource>();

  const send = useCallback(
    async (request: Request = {}) => {
      const { data, urlParams: rUrlParams, ignoreErrors } = request;
      if (withAbort && abortController.current) {
        abortController.current.cancel();
      }
      abortController.current = generateHttpRequestCancelSource();

      setState({
        ...DEFAULT_STATE,
        isProcessing: true,
        status: RequestStatuses.Processing,
        request,
      });

      const stringUrl = getUrl({ domain, url, urlParams: rUrlParams || urlParams });
      try {
        const result = await sendHttpRequest({
          url: stringUrl,
          method,
          data,
          cancelToken: abortController.current?.token,
          withCredentials,
        });
        setState({
          ...DEFAULT_STATE,
          isProcessing: false,
          status: RequestStatuses.Succeeded,
          result,
          request,
        });
        return result;
      } catch (e: any) {
        if (e?.name !== 'AbortError') {
          let status = RequestStatuses.Failed;
          if (e?.errors?.[0]?.statusCode === 401) {
            status = RequestStatuses.Unauthorized;
          }

          setState({
            ...DEFAULT_STATE,
            isProcessing: false,
            status,
            errors: e?.errors || e || [RequestErrors.UNKNOWN_ERROR],
            request,
          });
          if (!ignoreErrors) {
            throw e?.errors || e;
          }
        }
        return null;
      }
    },
    [withAbort, url, urlParams, domain, method, trackError],
  );

  const onRequest = useCallback<RequestFunc>(
    (data: Request = {}) => {
      if (formatData) {
        const prepared = formatData(data);
        return send(prepared);
      }
      return send(data);
    },
    [formatData, send],
  );

  const onClearState = useCallback(() => {
    setState(DEFAULT_STATE);
  }, []);

  return {
    state,
    onRequest,
    onClearState,
  };
};
