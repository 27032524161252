import React from 'react';

const InvalidCredentialsErrorIcon = () => {
  return (
    <svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 61c16.016 0 29-12.984 29-29S48.016 3 32 3 3 15.984 3 32s12.984 29 29 29Zm0 3c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32Z"
        fill="#F15132"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.877 32.877a1.3 1.3 0 0 1 1.84 0L45 37.161l4.283-4.284a1.3 1.3 0 0 1 1.84 1.84L46.84 39l4.283 4.283a1.3 1.3 0 0 1-1.84 1.84L45 40.84l-4.283 4.283a1.3 1.3 0 0 1-1.84-1.84L43.161 39l-4.284-4.283a1.3 1.3 0 0 1 0-1.84Z"
        fill="#F15132"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.799 31.622a1.5 1.5 0 0 1 .016-2.122l13.72-13.514a1.5 1.5 0 0 1 2.117.012l4.932 4.967a1.5 1.5 0 0 1-2.129 2.114l-3.88-3.907-3.417 3.366 2.433 2.415a1.5 1.5 0 1 1-2.113 2.13l-2.457-2.44-7.101 6.995a1.5 1.5 0 0 1-2.121-.016Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.023 31.598a5.502 5.502 0 0 0-.026 7.777 5.502 5.502 0 0 0 7.776.03 5.502 5.502 0 0 0 .03-7.78 5.503 5.503 0 0 0-7.78-.027Zm-2.155 9.89c-3.305-3.328-3.289-8.712.041-12.019a8.502 8.502 0 0 1 12.022.042 8.503 8.503 0 0 1-.044 12.022c-3.33 3.307-8.714 3.284-12.02-.044Z"
        fill="#fff"
      />
    </svg>
  );
};

export default InvalidCredentialsErrorIcon;
