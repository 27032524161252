import React from 'react';
import { AuthTabsValues, useAuthContext } from '@/context/AuthContext';
import { AuthForm } from '@/components/Auth/Form';
import { UseFormReturn } from 'react-hook-form';

interface Props {
  FormState: UseFormReturn<{ email: string; password: string }>;
  onRestorePassword: () => void;
}

const AuthLoginForm: React.FC<Props> = ({ FormState, onRestorePassword }) => {
  const { LoginRequest } = useAuthContext();

  return <AuthForm type={AuthTabsValues.Login} Request={LoginRequest} FormState={FormState} onRestorePassword={onRestorePassword} />;
};

export default AuthLoginForm;
