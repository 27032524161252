import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useMobile } from '@/hooks/useMobile';

interface Props {
  onClose: () => void;
  children?: any;
  fullWidth?: boolean;
}

const PAPER_SX = {
  boxSizing: 'border-box',
  backgroundImage: 'unset',
  backgroundColor: 'var(--iq-robot-background, #1b1f28)',
  gap: '24px',
  padding: '16px',
};
const PAPER_DESKTOP_SX = {
  ...PAPER_SX,
  width: '320px',
  maxWidth: '320px',
};
const PAPER_MOBILE_SX = {
  ...PAPER_SX,
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  maxWidth: '100%',
  margin: 0,
};

const Modal: React.FC<Props> = ({ onClose, children, fullWidth = false }) => {
  const isMobile = useMobile();

  return (
    <Dialog
      PaperProps={{
        sx: isMobile ? PAPER_MOBILE_SX : PAPER_DESKTOP_SX,
      }}
      open
      onClose={onClose}
      sx={
        isMobile
          ? {
            margin: fullWidth ? 0 : '16px',
          }
          : {}
      }
    >
      {children}
    </Dialog>
  );
};

export default React.memo(Modal);
