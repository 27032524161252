import React from 'react';

const NeedVpnErrorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="63"
      viewBox="0 0 54 63"
      fill="none">
      <path
        fill="#40CD1C"
        fillOpacity=".2"
        d="M49.477 41.694c-3.553 9.49-10.805 16.007-22.17 19.923a.922.922 0 0 1-.612 0C15.317 57.708 8.061 51.194 4.511 41.705c-3.105-8.3-2.929-17.405-2.774-25.438l.001-.045c.031-.69.053-1.413.064-2.208.026-1.664 1.372-3.041 3.064-3.134 4.49-.247 8.434-1.102 12.058-2.613 3.62-1.51 6.834-3.637 9.826-6.504a.378.378 0 0 1 .501 0c2.993 2.867 6.208 4.995 9.826 6.504 3.624 1.511 7.568 2.366 12.059 2.613 1.692.093 3.038 1.47 3.063 3.135.012.799.033 1.522.065 2.207.155 8.043.326 17.155-2.787 25.472Z"
      />
      <path
        fill="#34B813"
        fillRule="evenodd"
        d="M27 3.353c2.917 2.662 6.056 4.68 9.564 6.144 3.783 1.577 7.879 2.46 12.499 2.714 1.013.055 1.789.878 1.803 1.823.012.803.033 1.535.065 2.231.156 8.081.305 16.928-2.703 24.961-3.373 9.012-10.235 15.268-21.227 19.085-11.005-3.81-17.87-10.063-21.242-19.074-3-8.02-2.845-16.864-2.69-24.94l.002-.028c.031-.7.053-1.433.065-2.235.014-.945.79-1.768 1.803-1.823 4.62-.254 8.716-1.137 12.498-2.714 3.509-1.463 6.648-3.483 9.564-6.144ZM25.899.737a1.711 1.711 0 0 1 2.276.062c2.88 2.76 5.958 4.794 9.417 6.237 3.465 1.445 7.257 2.272 11.618 2.512 2.372.13 4.287 2.06 4.323 4.446a69.19 69.19 0 0 0 .065 2.202l.002.081c.153 7.981.333 17.319-2.873 25.884-3.719 9.933-11.32 16.697-22.985 20.716l-.007.002a2.423 2.423 0 0 1-.288.078l-.012.002c-.287.056-.58.055-.868 0l.257-1.31-.255 1.31h-.002c-.098-.02-.2-.047-.303-.082-11.676-4.01-19.285-10.772-23.001-20.706C.068 33.634.248 24.315.402 16.351l.002-.12.001-.044.001-.026c.03-.675.052-1.384.063-2.167.037-2.384 1.952-4.316 4.324-4.446 4.36-.24 8.152-1.067 11.618-2.512 3.459-1.443 6.536-3.477 9.417-6.237l.07-.062Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="m16.874 35.918-3.353-9.242h2.053l2.375 6.84 2.297-6.84h2.01l-3.36 9.242h-2.022ZM23.222 35.918v-9.242h3.04c1.15 0 1.902.046 2.252.139.537.138.988.44 1.35.904.363.465.544 1.065.544 1.8 0 .568-.105 1.045-.314 1.431-.209.386-.474.69-.797.91-.322.221-.649.368-.982.44-.451.088-1.106.132-1.964.132h-1.236v3.486h-1.893Zm1.894-7.679v2.623h1.037c.747 0 1.245-.048 1.497-.145.252-.097.449-.248.592-.454.143-.206.214-.445.214-.719 0-.336-.1-.613-.3-.832a1.315 1.315 0 0 0-.762-.41c-.226-.041-.68-.062-1.363-.062h-.915ZM31.983 35.918v-9.242h1.843l3.84 6.172v-6.172h1.759v9.242h-1.9l-3.783-6.027v6.027h-1.76Z"
      />
    </svg>
  );
};

export default NeedVpnErrorIcon;
