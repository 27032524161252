import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const run = async () => {
  const AUTH_MOUNT_CONTAINER_ID = 'auth-module-root';

  const authContainer = document.createElement('div');
  authContainer.setAttribute('id', AUTH_MOUNT_CONTAINER_ID);
  document.body.appendChild(authContainer);

  ReactDOM.createRoot(authContainer).render(<App />);
};

if (document.readyState !== 'loading') {
  run();
} else {
  document.addEventListener('DOMContentLoaded', run);
}
