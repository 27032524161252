import React from 'react';

interface Props {}

const AccountWarningIcon: React.FC<Props> = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="30.5" stroke="#FAB42E" stroke-width="3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9428 32.6152C26.0374 31.9418 27.5519 32.5801 29.0856 33.3755C29.9399 33.8177 31.0032 34.0496 31.9993 34.0496C32.9955 34.0496 34.0585 33.8175 34.9138 33.3753C36.4475 32.5799 37.9621 31.9426 40.0564 32.6151C41.9956 33.2389 43.2449 34.6359 43.9917 36.2443C44.7302 37.8347 44.9992 39.668 45 41.3164C45 43.0718 44.137 44.3679 42.9348 45.1874C41.7668 45.9837 40.2892 46.3321 38.9256 46.3321H25.0744C23.7109 46.3321 22.2333 45.9837 21.0653 45.1875C19.8631 44.3681 19 43.072 19 41.3166C19 39.6684 19.2689 37.8352 20.0073 36.2446C20.7539 34.6359 22.0035 33.2384 23.9428 32.6152ZM41.8083 43.5349C42.5374 43.0379 42.9999 42.3263 43 41.3169C42.9993 39.8573 42.757 38.3342 42.1777 37.0866C41.6069 35.8571 40.7321 34.9335 39.4444 34.5191C38.486 34.2114 37.7508 34.3002 36.8602 34.664C36.5434 34.7934 36.207 34.9576 35.8339 35.1511C34.6601 35.7581 33.2741 36.0496 31.9993 36.0496C31.1182 36.0496 30.1834 35.9106 29.3024 35.6238C28.9095 35.4959 28.5274 35.3386 28.1654 35.1512C26.7428 34.4134 25.854 34.1015 24.5548 34.5192C23.2674 34.9329 22.3923 35.8566 21.8213 37.0866C21.242 38.3346 21 39.8577 21 41.3166C21 42.3262 21.4625 43.0378 22.1917 43.5349C22.955 44.0552 24.0147 44.3321 25.0744 44.3321H38.9256C39.9854 44.3321 41.045 44.0552 41.8083 43.5349Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9998 17.666C30.269 17.666 28.7461 18.297 27.5219 19.5213C26.2977 20.7455 25.6665 22.2685 25.6665 23.9992C25.6665 25.7301 26.2977 27.2532 27.5221 28.4775C28.7464 29.7014 30.2694 30.3327 31.9998 30.3327C33.7305 30.3327 35.2538 29.7016 36.478 28.4772C37.7022 27.2529 38.3332 25.7301 38.3332 23.9992C38.3332 22.2687 37.702 20.7455 36.4777 19.521C35.2533 18.297 33.7304 17.666 31.9998 17.666ZM31.9998 19.666C30.7997 19.666 29.7854 20.0861 28.9361 20.9355C28.0867 21.7849 27.6665 22.7994 27.6665 23.9992C27.6665 25.1993 28.0867 26.2137 28.9362 27.0631C29.7858 27.9124 30.8003 28.3327 31.9998 28.3327C33.1999 28.3327 34.2145 27.9125 35.0637 27.063C35.913 26.2137 36.3332 25.1994 36.3332 23.9992C36.3332 22.7996 35.913 21.785 35.0635 20.9353C34.214 20.0861 33.1996 19.666 31.9998 19.666Z"
        fill="white"
      />
    </svg>
  );
};

export default React.memo(AccountWarningIcon);
