import React from 'react';
import Button from '@mui/material/Button';

interface Props {
  onClick: () => void;
  danger?: boolean;
  text: string;
}

const SubmitButton: React.FC<Props> = ({ onClick, text }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        textTransform: 'initial',
        backgroundColor: 'var(--iq-robot-alt-surface, #465066)',
        '&:hover': {
          backgroundColor: '#55607A',
        },
      }}
      fullWidth
    >
      {text}
    </Button>
  );
};

export default React.memo(SubmitButton);
