import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment, { InputAdornmentOwnProps } from '@mui/material/InputAdornment';
import React from 'react';

interface Props {
  show?: boolean;
  onToggle: () => void;
}

const InputPasswordVisibility: React.FC<Props & InputAdornmentOwnProps> = (props) => {
  const { show, onToggle, ...rest } = props;
  return (
    <InputAdornment {...rest}>
      <IconButton onClick={onToggle}>{show ? <Visibility /> : <VisibilityOff />}</IconButton>
    </InputAdornment>
  );
};

export default React.memo(InputPasswordVisibility);
