import React, { useEffect } from 'react';
import FormHeader from './FormHeader/FormHeader';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from '@/hooks/useTranslation';

import { Loader } from '@/components/Loader';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useAuthContext } from '@/context/AuthContext';
import SuccessMessage from './SuccessMessage/SuccessMessage';

import { ModalType, useModalContext } from '@/context/ModalContext';
import { TextField } from '@/components/TextField';
import { formatFieldErrors } from '@/utils/error/formatFieldErrors';
import { AnalyticClient, UserScheme, RequestStatuses } from '@repo/utils';

interface Props {
  onBack: () => void;
}

const schema = z.object({
  email: UserScheme.email(),
});

const ResetPasswordForm: React.FC<Props> = ({ onBack }) => {
  const { t } = useTranslation();
  const {
    onRestorePassword,
    RestorePasswordRequest: {
      state: { status, isProcessing, errors: requestErrors },
      onClearState,
    },
  } = useAuthContext();
  const { open } = useModalContext();

  useEffect(() => {
    if (requestErrors && requestErrors.length) {
      open(requestErrors?.[0]?.message as unknown as ModalType);
      onClearState();
    }
    if (status === RequestStatuses.Succeeded) {
      AnalyticClient.track({
        type: 'Forgot password - Success',
      });
      return;
    }
  }, [requestErrors, status]);

  useEffect(
    () => () => {
      onClearState();
    },
    [],
  );

  const {
    handleSubmit: onSubmit,
    formState: { errors },
    control,
    getValues,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(schema),
  });

  const email = getValues('email');

  const handleSubmit = onSubmit((data) => {
    onRestorePassword({
      email: data.email,
    });
  });

  if (status === RequestStatuses.Succeeded) {
    return (
      <Box component="form" sx={{ gap: '12px' }} onSubmit={onBack}>
        <FormHeader onBack={onBack} />
        <SuccessMessage email={email} />
      </Box>
    );
  }

  return (
    <Box component="form" sx={{ gap: '12px' }} onSubmit={handleSubmit}>
      <FormHeader onBack={onBack} />
      <Typography
        variant="body1"
        sx={{
          color: 'var(--common-text-grey, #98a4b3)',
          marginBottom: '28px',
        }}
      >
        {t('text.reset_password_text')}
      </Typography>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            {...formatFieldErrors({
              t,
              errorType: errors?.email?.type,
              params: { field: t('field.email.placeholder') },
            })}
            fullWidth
            sx={{ marginBottom: '12px' }}
            type="email"
            placeholder={t('field.email.placeholder')}
          />
        )}
      />
      <Button
        fullWidth
        disabled={isProcessing || !isDirty || !isValid}
        sx={{ textTransform: 'none', mt: 1 }}
        type="submit"
      >
        {isProcessing ? <Loader /> : t('button.reset_password')}
      </Button>
    </Box>
  );
};

export default ResetPasswordForm;
