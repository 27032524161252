import { RequestMethods, GetConfigRobotsApiUrl } from '@repo/utils';
import { useRequest } from '@/hooks/useRequest';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface Config {
  support_email: string;
  domain: string;
  max_earn_demo: number;
  init_bet: number;
  platform_url: string;
  deposit_url: string;
  withdraw_url: string;
  transfer_url?: string;
  transactions_url?: string;
  a_id: number;
  platform_privacy_policy_url: string;
  platform_terms_conditions_url: string;
  robot_privacy_policy_url: string;
  robot_terms_conditions_url: string;
}

export interface ConfigState {
  value?: Config;
}

interface Context {
  configState: ConfigState;
}

const ConfigContext = createContext({} as Context);

export const useConfigContext = () => useContext(ConfigContext);

const ConfigContextProvider = ConfigContext.Provider;

interface Props {
  children?: any;
}

export const ConfigProvider = (props: Props) => {
  const { children } = props;
  const [configState, setConfigState] = useState<Context['configState']>({
    value: undefined,
  });

  const GetConfigRequest = useRequest({
    url: GetConfigRobotsApiUrl,
    method: RequestMethods.Post,
    withCredentials: false,
  });

  const handleGetConfig = useCallback(async () => {
    const response = await GetConfigRequest.onRequest();
    if (response.code !== 200) {
      return;
    }

    setConfigState({ value: response.result });
  }, []);

  useEffect(() => {
    handleGetConfig();
  }, []);

  const contextValue = useMemo(
    () => ({
      configState,
    }),
    [configState],
  );

  return <ConfigContextProvider value={contextValue}>{children}</ConfigContextProvider>;
};
