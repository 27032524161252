import React from 'react';
import styled from '@emotion/styled';

interface Props {
  children: any;
  withText?: boolean;
}

const HeaderRoot = styled.div<{ withText?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: ${(props) => (props.withText ? 'space-between' : 'flex-end')};
`;

const Header: React.FC<Props> = ({ children, withText = false }) => {
  return <HeaderRoot withText={withText}>{children}</HeaderRoot>;
};

export default Header;
