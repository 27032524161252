import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { formatFieldErrors } from '@/utils/error/formatFieldErrors';
import { TextField } from '../../TextField';
import { useConfigContext } from '@/context/ConfigContext';
import { AuthTabsValues, useAuthContext } from '@/context/AuthContext';
import { ModalType, useModalContext } from '@/context/ModalContext';
import { useTranslation } from '@/hooks/useTranslation';
import { TermsText } from '@/components/TermsText';
import { Loader } from '@/components/Loader';
import { UseRequest } from '@/hooks/useRequest';
import { UseFormReturn } from 'react-hook-form';
import { ResetPasswordButton } from '@/components/ResetPasswordButton';
import { useBoolean } from '@/hooks/useBoolean';
import { useMobile } from '@/hooks/useMobile';
import { InputPasswordVisibility } from '@/components/InputPasswordVisibility';
import { useAnalyticsAuthForm } from '@/lego/hooks/useAnalyticsAuthForm';
import { AnalyticClient, RequestStatuses } from '@repo/utils';

interface Props {
  type: AuthTabsValues;
  Request: UseRequest;
  FormState: UseFormReturn<{ email: string; password: string }>;
  onRestorePassword?: () => void;
}

const AuthForm: React.FC<Props> = ({ type, Request, FormState, onRestorePassword }) => {
  const { t } = useTranslation();
  const {
    configState: { value },
  } = useConfigContext();
  const { meState } = useAuthContext();
  const { open } = useModalContext();
  const showPassword = useBoolean();
  const isMobile = useMobile();
  const isRegister = type === 'register';
  const isLogin = type === 'login';
  const {
    handleSubmit: onSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = FormState;
  useAnalyticsAuthForm(Request.state, type);
  const showLoader =
    Request.state.isProcessing ||
    Request.state.status === RequestStatuses.Succeeded ||
    Boolean(meState?.value?.traderId);

  const handleSubmit = onSubmit((data) => {
    if (isRegister) {
      Request.onRequest({
        data: {
          email: data.email,
          password: data.password,
          language: AnalyticClient.getLanguage(),
          afftrack: value?.a_id,
        },
      });
    }
    if (isLogin) {
      Request.onRequest({
        data: {
          email: data.email,
          password: data.password,
          ad_id: value?.a_id,
        },
      });
    }
  });

  useEffect(() => {
    const hasErrors = Boolean(Request.state.errors?.length);
    if (hasErrors) {
      const error = Request.state.errors?.[0]!;
      const errorMessage = error?.message;
      open(errorMessage as unknown as ModalType);
      clearErrors?.();
    }
  }, [Request.state.errors, Request.state.status]);

  useEffect(
    () => () => {
      Request.onClearState();
    },
    [],
  );

  return (
    <Box component="form" autoComplete="off" sx={{ gap: '12px' }} onSubmit={handleSubmit}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            {...formatFieldErrors({
              t,
              errorType: errors?.email?.type,
              params: { field: t('field.email.placeholder') },
            })}
            fullWidth
            sx={{ marginBottom: isMobile ? '16px' : '12px' }}
            type="email"
            placeholder={t('field.email.placeholder')}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            {...formatFieldErrors({
              t,
              errorType: errors?.password?.type,
              params: {
                field: t('field.password.placeholder'),
                min: 6,
              },
            })}
            fullWidth
            sx={{ marginBottom: '16px' }}
            type={showPassword.value ? 'text' : 'password'}
            placeholder={t('field.password.placeholder')}
            InputProps={{
              endAdornment: (
                <InputPasswordVisibility
                  position="end"
                  show={showPassword.value}
                  onToggle={showPassword.onToggle}
                  sx={{ opacity: 0.4 }}
                />
              ),
            }}
          />
        )}
      />
      {isLogin && <ResetPasswordButton onClick={onRestorePassword} />}
      <Button
        fullWidth
        disabled={showLoader}
        type="submit"
        variant="contained"
        color="primary"
        sx={{ marginTop: '8px' }}
      >
        {showLoader ? <Loader /> : t(isRegister ? 'text.register' : 'text.login')}
      </Button>
      {isRegister && <TermsText />}
    </Box>
  );
};

export default AuthForm;
