import { useState, useCallback } from 'react';

export type UseBoolean = ReturnType<typeof useBoolean>;
export const useBoolean = (defaultValue: boolean = false) => {
  const [value, setValue] = useState(defaultValue);

  const onTrue = useCallback(() => setValue(true), []);
  const onFalse = useCallback(() => setValue(false), []);
  const onToggle = useCallback(() => setValue((p) => !p), []);

  return { value, onTrue, onFalse, onToggle };
};
