import React from 'react';
import { useTranslation } from '@/hooks/useTranslation';
import Button from '@mui/material/Button';
import { useMobile } from '@/hooks/useMobile';

interface Props {
  onClick?: () => void;
}

const ResetPasswordButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();
  const isMobile = useMobile();

  return (
    <Button
      sx={{
        margin: isMobile ? '8px 0px 24px -4px': '0px 0px 12px -4px',
        padding: '4px',
        width: 'fitContent',
        height: 'auto',
        justifyContent: 'flex-start',
        textTransform: 'initial',
        lineHeight: 1.5,
        fontWeight: '500'
      }}
      onClick={onClick}
      variant="text"
      disableRipple
    >
      {t('text.forgot_password_question')}
    </Button>
  );
};

export default ResetPasswordButton;
