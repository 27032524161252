import { useCallback, useMemo, useState } from 'react';

export const useModalStack = <T>() => {
  const [stack, setStack] = useState<T[]>([]);

  const open = useCallback((newModal: T) => {
    setStack((prev) => [...prev.filter((s) => s !== newModal), newModal]);
  }, []);

  const close = useCallback((closeModal: T) => {
    setStack((prev) => prev.filter((modal) => modal !== closeModal));
  }, []);

  const closeAll = useCallback(() => {
    setStack([]);
  }, []);

  return useMemo(
    () => ({
      stack,
      closeAll,
      open,
      close,
    }),
    [stack, closeAll, open, close],
  );
};
