import { TextFieldProps } from '@mui/material/TextField';
import { ReturnT } from '@/hooks/useTranslation';

interface Props {
  t: ReturnT['t'];

  errorType?: string;
  params?: Record<string, string | number>;
}

export const formatFieldErrors = ({ t, errorType, params }: Props): Pick<TextFieldProps, 'helperText' | 'error'> => {
  if (!errorType) {
    return {
      helperText: '',
      error: false,
    };
  }
  const translationKey = `error.string.${errorType}`;
  return {
    helperText: t(translationKey, params),
    error: Boolean(errorType),
  };
};
