import CloseSharp from '@mui/icons-material/CloseSharp';
import IconButton from '@mui/material/IconButton';
import React from 'react';

interface Props {
  onClick: () => void;
  color?: 'default' | 'white';
}

const CloseButton: React.FC<Props> = ({ onClick, color = 'default' }) => {
  return (
    <IconButton
      disableRipple
      onClick={onClick}
      sx={{
        all: 'unset',
        display: 'flex',
        justifyContent: 'flex-end',
        cursor: 'pointer',
      }}
    >
      <CloseSharp
        sx={{
          color: color === 'white' ? 'color: var(--common-text-white, #e9e8eb)' : 'var(--common-text-grey, #98a4b3)',
        }}
      />
    </IconButton>
  );
};

export default CloseButton;
