import { useModalContext } from '@/context/ModalContext';
import React from 'react';
import { ModalMapper } from '../ModalMapper';

interface Props {}

const ModalStack: React.FC<Props> = (props) => {
  const {
    modalState: { stack: modalStack },
  } = useModalContext();

  return modalStack.map((modalType) => <ModalMapper key={modalType} name={modalType} />);
};

export default React.memo(ModalStack);
