import React from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from '@/hooks/useTranslation';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { useMobile } from '@/hooks/useMobile';

const Root = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  margin-top: ${(props) => props.isMobile && '16px'};
`;
const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

interface Props {
  onBack: () => void;
}

const FormHeader: React.FC<Props> = ({ onBack }) => {
  const { t } = useTranslation();
  const isMobile = useMobile();

  return (
    <Root isMobile={isMobile}>
      <IconButton
        sx={{
          transform: 'rotate(90deg)',
          padding: 0,
          width: '30px',
          height: '30px',
          top: '-1px',
        }}
        onClick={onBack}
      >
        <ExpandMore
          sx={{
            width: '100%',
            height: '100%',
          }}
        />
      </IconButton>
      <TextContainer>
        <Typography
          variant="h3"
          sx={{
            fontSize: 'var(--text-h3-size, 22px)',
            fontWeight: 700,
          }}
        >
          {t('text.forgot_password')}
        </Typography>
      </TextContainer>
    </Root>
  );
};

export default FormHeader;
